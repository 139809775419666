import React, { Component } from 'react';
import { LoaderGif2 } from '../commons/loaderGif.js';
import withWindowDimensions from '../commons/withWindowDimensions.js';
import { myConfig } from '../commons/config.js';

class Decomm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countdownSeconds: 15 //? in seconds
        };
    
        this.intervalRef = React.createRef();
    }

    componentDidMount() {
        this.intervalRef.current = setInterval(() => {
            if (this.state.countdownSeconds > 0) {
                this.setState((prevState) => ({
                    countdownSeconds: prevState.countdownSeconds - 1
                }));
            } 
            else {
                this.stopTimer();
            }
        }, 1000);
    }
    
    componentWillUnmount() {
        this.stopTimer();
    }

    stopTimer = () => {
        clearInterval(this.intervalRef.current);
        window.location.href = myConfig.NDBX_URL;
    };

    render() {
        const Line1 = <span>We're Moving to</span>;
        const Line2 = <span>Serve You Better!</span>;
        const Body = 
            <div>
                Our website will be relocating to <a href={myConfig.NDBX_URL} target="_self">{myConfig.NDBX_URL}</a> on October 21, 2024. We look forward to providing you with an enhanced experience. 
                <br/><br/>
                Thank you for your loyalty!
            </div>;
        const LoadingMsg = "Redirecting you in ";
        if (this.props.isSm) {
            return (
                <>
                    <div className="decommission decommission-mobile__container">
                        <div className="decommission-mobile__logo-header">
                            <img className="decommission-mobile__logo" src={require('../assets/img/az-logo.png')}/>
                        </div>
                        <div className="decommission-mobile__content">
                            <header className="decommission-mobile__header">
                                <h1 className="line1">{Line1}</h1>
                                <h1 className="line2">{Line2}</h1>
                            </header>
                            <section className="decommission-mobile__body">
                                {Body}
                            </section>
                            <footer className="decommission-mobile__footer">
                                <LoaderGif2 css="decommission-mobile__loader"/>{LoadingMsg}{this.state.countdownSeconds}
                            </footer>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <div className="decommission decommission__background">
                    <div className="decommission decommission__container">
                        <img className="decommission__logo" src={require('../assets/img/az-logo.png')}/>
                        <main className="decommission__main">
                            <header className="decommission__header">
                                <h1 className="line1">{Line1}</h1>
                                <h1 className="line2">{Line2}</h1>
                            </header>
                            <section className="decommission__body">
                                {Body}
                            </section>
                        </main>
                        <footer className="decommission__footer">
                            <LoaderGif2 css="decommission__loader"/>{LoadingMsg}{this.state.countdownSeconds}
                        </footer>
                    </div>
                </div>
            </>
        );
    }
}

export default withWindowDimensions(Decomm);
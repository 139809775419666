import React, { Component, Fragment } from 'react';
import { ReportLoader } from '../commons/utilityViews.js';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import 'react-vertical-timeline-component/style.min.css';
import { apiModule } from '../commons/apiCall';
import Nav from '../components/AgentPolicyList/Nav';
import withWindowDimensions from '../commons/withWindowDimensions';
import ChangePersonalData from './changePersonalData';
import FundTransferAgreement from './fundTransferAgreement';
import { myConfig } from '../commons/config.js';
import Select from "react-select";
import routeChange from '../commons/routeChange';
import ServiceDatatable from '../components/PolicyServices/serviceDatatable.js';
class AgentUpdatingClient extends Component {
    state = {
        // Permissions and page loading
        isLoading: true,

        // Utility
        errorMessage: "",
        disclaimerLabel: "This feature is only available in ",

        pageTitle: "Policy Services",
        pageIcon: "",
        serviceOptions: [],
        service: null,
    }

    constructor(props) {
        super(props);
    }

    // Component lifecycle methods
    async componentDidMount() {
        this.setState({
            isLoading: true,
        });
        try {
            await this.fetchServices();
            this.setState({ isLoading: false });
        } catch (e) {
            this.displayError(myConfig.GENERIC_ERROR_MESSAGE)
        }
        this.setState({ isLoading: false });
    }

    displayError = (message) => {
        if (message) {
            this.setState({ errorMessage: `${message}` });
        }
    }

    async fetchServices() {
        let access_token = this.props.getSession().access_token;
        let headerParams = { x_auth: access_token, dataListId: "assistPolicyServices" };
        let serviceOptions = [];
        const res = await apiModule('get_datalist', headerParams);
        serviceOptions = Object.keys(res).map(key => {
            return {
                value: key, label: res[key]
            }
        })
        this.setState({
            serviceOptions,
            service: serviceOptions[0],
        })
    }

    setPageTitle = (pageTitle, pageIcon) => {
        this.setState({
            pageTitle,
            pageIcon,
        })
    }

    serviceRedirect = async (value) => {
        value = value ? value.toLowerCase() : "";
        switch (value) {
            case "digital_id":
                routeChange(this.props.history, `${this.props.match.url}/digital-id`);
                break;
            case "fta":
                routeChange(this.props.history, `${this.props.match.url}/fta`);
                break;
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <ReportLoader show={this.state.isLoading} />
            )
        }
        else {
            const disclaimerStyle = {
                color: "rgb(232, 109, 61)", 
                fontSize: "11px", 
                marginLeft: "6px"
            }
            return (
                <Fragment>
                    <div className="dashboard-section">
                        <Switch>
                            <Route
                                path={this.props.match.url + "/change-personal-data"}
                                render={() => <ChangePersonalData {...this.props} />}
                            />
                            <Route
                                path={this.props.match.url + "/new-fund-transfer-agreement"}
                                render={() => <FundTransferAgreement {...this.props} />}
                            />
                            <Route
                                path={this.props.match.url}
                                render={() => (
                                    <Fragment>
                                        {
                                            this.state.errorMessage.length > 0 ? (
                                                <div className="bg-error py-1 px-4">
                                                    <p className="text-white mb-0 font-neo-semibold">ERROR: {this.state.errorMessage}</p>
                                                </div>) : ""
                                        }
                                        <div className="dashboard-container container-fluid p-3">
                                            <div className="dashboard-header">
                                                <h6 className="font-neo-bold text-darkgray">
                                                    <Switch>
                                                        <Route
                                                            path={this.props.match.url + "/*"}
                                                            render={() => {
                                                                return (
                                                                    <Fragment>
                                                                        <Link to={this.props.match.url}><i class="fas fa-arrow-left text-breeze mr-3"></i></Link>
                                                                        {this.state.pageIcon && <i className={`${this.state.pageIcon} mr-2`}></i>}{this.state.pageTitle}
                                                                    </Fragment>
                                                                )
                                                            }}
                                                        />
                                                        <Route exact path={this.props.match.url} render={() => "Policy Services"} />
                                                    </Switch>
                                                </h6>
                                                {this.props.getViewAs}
                                            </div>
                                            <div className="dashboard-body">
                                                <Switch>
                                                    <Route exact path={this.props.match.url} render={() => (
                                                        <Fragment>
                                                            <Nav policyList="active" />
                                                            <div className="my-4 mx-md-2">
                                                                <small style={{ fontSize: ".75rem" }}>Select Type of Transaction</small>
                                                                <Select
                                                                    options={this.state.serviceOptions}
                                                                    isSearchable={false}
                                                                    className={`datatable-select ml-0 ${this.props.isMd ? "w-100" : "w-16rem"}`}
                                                                    classNamePrefix="datatable-select"
                                                                    placeholder="Select..."
                                                                    value={this.state.service}
                                                                    styles={{
                                                                        option: (provided) => ({
                                                                            ...provided,
                                                                            fontFamily: "neo_semibold",
                                                                            fontSize: ".6rem",
                                                                            color: "#9A9B9C",
                                                                            backgroundColor: "white"
                                                                        }),
                                                                        menu: () => ({
                                                                            position: "absolute",
                                                                            border: 0,
                                                                            zIndex: "1090",
                                                                            backgroundColor: "white",
                                                                            borderRadius: "5px",
                                                                            boxShadow: "2px 2px 2px #DDDEE0"
                                                                        }),
                                                                    }}
                                                                    onChange={(service) => {
                                                                        this.setState({
                                                                            service
                                                                        })
                                                                        if (service.value !== "DIGITAL_ID" && service.value !== "FTA") {
                                                                            this.state.disclaimerLabel = "This feature is only available in "; 
                                                                        } else if(service.value === "DIGITAL_ID") {
                                                                            this.state.disclaimerLabel = "This is only available for unauthenticated clients"
                                                                        } else if(service.value === "REINSTATEMENT_CALC" || service.value === "E-CARD") {
                                                                            this.state.disclaimerLabel = "This feature is only available in ";
                                                                        } else {
                                                                            this.state.disclaimerLabel = "";
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="mt-2" style={{ display: this.state.disclaimerLabel ? "block": "none" }}>
                                                                    <label style={ disclaimerStyle }>
                                                                        { this.state.disclaimerLabel }
                                                                        {this.state.service.value !== "DIGITAL_ID" && this.state.service.value !== "FTA" ?
                                                                            <a href={myConfig.NDBX_URL} target="_blank">{myConfig.NDBX_URL}</a> 
                                                                            : "" }
                                                                        <span className="text-danger">*</span>
                                                                    </label>
                                                                </div>
                                                                {(this.state.service.value === "DIGITAL_ID" || this.state.service.value === "FTA") &&
                                                                    <button
                                                                        onClick={() => this.serviceRedirect(this.state.service.value)}
                                                                        className="lh-1 d-flex align-items-center justify-content-center btn btn-dropdowncolor rounded-pill text-uppercase font-p65rem font-neo-semibold text-white datatable-search__btn mt-4 px-5"
                                                                        disabled={!this.state.service}
                                                                        >submit
                                                                    </button>
                                                                }
                                                            </div>
                                                        </Fragment>
                                                    )
                                                    } />
                                                    <Route
                                                        path={this.props.match.url + "/:type"}
                                                        render={() => {
                                                            return (
                                                                <ServiceDatatable {...this.props} setPageTitle={(a, b) => this.setPageTitle(a, b)} displayError={(a) => this.displayError(a)} />
                                                            )
                                                        }
                                                        }
                                                    />
                                                </Switch>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            />
                        </Switch>
                    </div>
                </Fragment>
            );
        }

    }
}


export default withRouter(withWindowDimensions(AgentUpdatingClient));
